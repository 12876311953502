(function () {
    $(document).ready(function () {
       console.log('contactossss')
        $selector = $('.container_contacts .wrapper_form');

        function showMessageError(message) {
            $selector.find('#pnlMessageSuccess').hide();
            $selector.find('#pnlMessage #message').html(message);
            $selector.find('#pnlMessage').show();
        }

        function showMessageSuccess(message) {
            $selector.find('#pnlMessage').hide();
            $selector.find('#pnlMessageSuccess #messageSuccess').html(message);
            $selector.find('#pnlMessageSuccess').show();
        }

        $selector.find('#enviar_consulta').click(function (e) {
            var name = $selector.find('#name').val().trim(),
                email = $selector.find('#email').val().trim(),
                telefono = $selector.find('#telefono').val().trim(),
                mensaje = $selector.find('#mensaje').val().trim();

            if(!name){
                showMessageError("Es necesario que ingrese su nombre.");
                return false;
            }

            if(!email){
                showMessageError("Es necesario que ingrese su correo.");
                return false;
            }

            if(!telefono){
                showMessageError("Es necesario que ingrese su teléfono.");
                return false;
            }

            if(!mensaje){
                showMessageError("Es necesario que ingrese un mensaje.");
                return false;
            }
            $selector.find('form').submit();
            $selector.find('form')[0].reset();
            showMessageSuccess('Su mensaje fue enviado, gracias por escribirnos.');
        })
    })
})();