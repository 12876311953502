(function(){

    $('#carousel_brands').carousel({
        interval: 4000
        
    });

    $('.carousel-showmanymoveone .item').each(function(){
        var itemToClone = $(this);

        for (var i=1;i<4;i++) {
            itemToClone = itemToClone.next();

            // wrap around if at end of item collection
            if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
            }

            // grab item, clone, add marker class, add to collection
            itemToClone.children(':first-child').clone()
                .addClass("cloneditem-"+(i))
                .appendTo($(this));
        }
    });

    $('.maps_google').click(function () {
        $('.maps_google iframe').css("pointer-events", "auto");
    });

    $( ".maps_google" ).mouseleave(function() {
        $('.maps_google iframe').css("pointer-events", "none");
    });

}());