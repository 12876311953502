(function () {
    $(document).ready(function () {
        $(window).scroll(function (event) {
            
            if($(this).scrollTop()>=150){
                $('.wrapper_menu').addClass('active')
            }else{
                $('.wrapper_menu').removeClass('active')
            }
        });

        
        
        /////////////////  MENU MOBILE

        // ABRIENDO menu mobile
        $('.container_app_header .wrapper_menu .btn_open_menu_mobile').click(function (e) {
            console.log('abriendo');
            $('.container_app_header .wrapper_menu .menu').addClass('show_mobile');
        });
        
        // mostrando submenus de menu mobiles
        $('.container_app_header .wrapper_menu .item_submenu .btn_submenu').click(function (e) {
            var selectContSubmenu = $(this).siblings('.cont_submenu');
           selectContSubmenu.slideToggle('slow','linear');
        });

        // Cerrando menu mobile
        $('.container_app_header .wrapper_menu .btn_close').click(function (e) {
            $('.container_app_header .wrapper_menu .menu').removeClass('show_mobile');
        });


    })
})();
